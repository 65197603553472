<template>
  <el-card v-loading="loading" shadow="hover" :body-style="{padding:'1rem'}" style="width: 100%;">
    <template #header>
      <div class="y-pointer flex-def flex-zBetween flex-cCenter">
        <span>
          <i class="el-icon-bangzhu"></i>
          系统授权信息
        </span>
      </div>
    </template>
    <div style="height: 18rem">
      <el-row :gutter="20" class="row-col">
        <el-col :span="2"><i class="el-icon-caret-right"></i></el-col>
        <el-col :span="10" align="left">系统域名信息</el-col>
        <el-col :span="12" style="text-align: right">{{ sys.site_domain }}</el-col>
      </el-row>
      <el-row :gutter="20" class="row-col">
        <el-col :span="2"><i class="el-icon-caret-right"></i></el-col>
        <el-col :span="10" align="left">ip地址</el-col>
        <el-col :span="12" align="right">{{ sys.site_ip }}</el-col>
      </el-row>
      <el-row :gutter="20" class="row-col">
        <el-col :span="2"><i class="el-icon-caret-right"></i></el-col>
        <el-col :span="10" align="left">系统授权状态</el-col>
        <el-col :span="12" align="right">
          <el-tag effect="dark" :type="sys.copy_status ? 'success' : 'info'" size="mini">{{sys.copy_status ? '已授权' : '未授权'}}</el-tag>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="row-col">
        <el-col :span="2"><i class="el-icon-caret-right"></i></el-col>
        <el-col :span="10" align="left">授权生效时间</el-col>
        <el-col :span="12" align="right">{{ sys.copy_start_time | date }}</el-col>
      </el-row>
      <el-row :gutter="20" class="row-col">
        <el-col :span="2"><i class="el-icon-caret-right"></i></el-col>
        <el-col :span="10" align="left">授权平台数量</el-col>
        <el-col :span="12" align="right">{{ sys.copy_uni_num }}</el-col>
      </el-row>
      <el-row :gutter="20" class="row-col">
        <el-col :span="2"><i class="el-icon-caret-right"></i></el-col>
        <el-col :span="10" align="left">当前系统版本</el-col>
        <el-col :span="12" align="right">{{ sys.sys_version }}</el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>

export default {
  name: "index_table",
  components: {},
  data() {
    return {
      sys: {
        site_ip: "",
        site_domain:"",
        copy_status:false,
        copy_start_time:"",
        copy_uni_num:0,
        sys_version:"",
      },
      loading:false,
    }
  },
  mounted() {
    this.sys.domain = location.host
    this.load();
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.setting.sys().then(res=>{
        if (!res.copy_status){
          this.$message.error("当前站点未授权");
          this.$router.push({name:"managerUni"});
          return
        }
        this.sys = res;
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>
.row-col {
  padding: .6rem 0;
  font-size: .8rem
}

.row-col:nth-child(odd) {
  background-color: #f8f8f8;
}

#main {
  height: 400px;
  width: 100%;
}
</style>