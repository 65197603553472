<template>
  <el-card shadow="hover" :body-style="{padding:'1rem'}" style="width: 100%;position: relative">
    <template #header>
      <div class="y-pointer flex-def flex-zBetween flex-cCenter" style="position: relative;height:100%">
        <span><i class="el-icon-data-analysis"></i>系统趋势统计</span>
        <div class="flex-def" style="height: 100%;position: absolute;right: -1rem">
          <div @click="tapTag(0)" :class="active_tag === 0?'active':''" class="tab-btn">会员增长</div>
          <div @click="tapTag(1)" :class="active_tag === 1?'active':''" class="tab-btn">参与趋势</div>
          <div @click="tapTag(2)" :class="active_tag === 2?'active':''" class="tab-btn">资金趋势</div>
          <div @click="tapTag(3)" :class="active_tag === 3?'active':''" class="tab-btn">卡券趋势</div>
        </div>
      </div>
    </template>
    <div style="width: 100%">
      <member v-if="active_tag === 0"></member>
      <order v-if="active_tag === 1"></order>
      <cash v-if="active_tag === 2"></cash>
      <coupon v-if="active_tag === 3"></coupon>
    </div>
  </el-card>
</template>

<script>
import member from "@/view/home/index_echart/member";
import order from "@/view/home/index_echart/order";
import cash from "@/view/home/index_echart/cash";
import coupon from "@/view/home/index_echart/coupon";
export default {
  name: "index_echart",
  components: {
    member,
    coupon,
    order,
    cash,
  },
  data() {
    return {
      active_tag:0,
    }
  },
  methods:{
    tapTag(index){
      this.active_tag = index
    }
  }
}
</script>

<style scoped>
.tab-btn{
  padding: 0 1rem;
  font-size: .8rem;
}
.active{
  border-bottom: 2px solid #62efff;
  background-color: #f8f8f8;
}

</style>