<template>
  <div v-loading="loading" id="main"></div>
</template>

<script>

export default {
  name: "member",
  components: {

  },
  data() {
    return {
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.active.homeMember().then(res=>{
        this.loading = false;
        let myChart = this.$echarts.init(document.getElementById("main"));
        myChart.setOption({
          title: {
            text: "会员增长数据统计",
          },
          tooltip: {},
          legend: {
            right:"0",
            orient:"vertical",
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: res.label
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: res.value,
            type: 'line',
            smooth: true,
            areaStyle: {}
          }]
        });
      })
    }
  }
}
</script>

<style scoped>

#main {
  height: 350px;
  width: 100%;
}
</style>