<template>
  <div v-loading="loading" class="flex-def" style="width: 100%;height: 100%;">
    <el-table :data="list" border style="width: 100%">
      <el-table-column label="状态" width="70">
        <template #default="s">
          <el-tag v-if="s.row.status===2" size="mini" effect="dark" type="success">已支付</el-tag>
          <el-tag v-else size="mini" effect="dark" type="info">未支付</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="order_no" label="订单编号" width="210"></el-table-column>
      <el-table-column prop="member.nickname" label="支付会员"></el-table-column>
      <el-table-column prop="fee" label="金额" width="80"></el-table-column>
      <el-table-column label="下单时间">
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
  name: "order",
  data(){
    return{
      list:[],
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.order.search().then(res=>{
        this.list = res.list;
        this.loading = false;
      })
    }
  }
}
</script>
<style>
.el-table td{
  padding: .5rem 0;
}
</style>
<style scoped>

</style>