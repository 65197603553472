<template>
  <div v-loading="loading" class="flex-def" style="width: 100%;height: 100%;">
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="title" label="商家名称"></el-table-column>
      <el-table-column prop="link_phone" label="联系电话"></el-table-column>
      <el-table-column label="申请时间">
        <template #default="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template #default="s">
          <el-button @click="judge(s.row)" type="success" size="mini" icon="el-icon-s-check">审核</el-button>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
  name: "merchant",
  data(){
    return{
      list:[],
      loading:false,
    }
  },
  mounted() {
    this.searchSubmit();
  },
  methods:{
    judge(item){
      item.status = true;
      this.$api.merchant.update(item).then(()=>{
        this.$message.success("操作成功");
        this.searchSubmit();
      })
    },
    searchSubmit() {
      this.loading = true;
      this.$api.merchant.search({
        page: this.page,
        status:false
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
        this.loading = false;
      })
    },
  }
}
</script>
<style>
.el-table td{
  padding: .5rem 0;
}
</style>
<style scoped>

</style>