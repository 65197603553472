<template>
  <y_layout>
    <el-alert v-if="tips" :title="tips" type="warning" show-icon :closable="false"></el-alert>
    <index_desc style="margin-top: 1rem"></index_desc>
    <el-row :gutter="20" style="margin-top: 1rem">
      <el-col :span="10" type="flex" justify="center" align="middle">
        <index_sys v-if="$store.state.user.info.founder === 1"></index_sys>
        <el-card v-else shadow="hover" :body-style="{padding:'1rem'}" style="width: 100%;">
          <template #header>
            <div class="y-pointer flex-def flex-zBetween flex-cCenter">
              <span>
                <i class="el-icon-bangzhu"></i>
                待审商家
              </span>
            </div>
          </template>
          <el-scrollbar style="height: 18rem;width: 100%">
            <merchant></merchant>
          </el-scrollbar>
        </el-card>
      </el-col>
      <el-col :span="14" type="flex" justify="center" align="middle">
        <index_wait></index_wait>
      </el-col>
      <el-col style="margin-top: 1rem" :span="24" type="flex" justify="center" align="middle">
        <index_echart></index_echart>
      </el-col>
    </el-row>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import index_desc from "@/view/home/index_desc";
import index_echart from "@/view/home/index_echart";
import index_sys from "@/view/home/index_sys";
import index_wait from "@/view/home/index_wait";
import merchant from "@/view/home/index_wait/merchant";
export default {
  components:{
    y_layout,
    index_desc,
    index_echart,
    // index_table,
    index_sys,
    index_wait,
    merchant,
  },
  name: "index",
  data(){
    return{
      tips:"",
    }
  },
  mounted() {
    // console.log(this.$route.params.uni_acid);
  }
}
</script>
<style>
.el-card__header{
  padding: 0 1rem;
  line-height: 2.6rem;
  font-size: .9rem;
}
</style>
<style scoped>

</style>