<template>
  <div v-loading="loading" id="main"></div>
</template>

<script>

export default {
  name: "order",
  components: {},
  data() {
    return {
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.active.homeJoin().then(res=>{
        this.loading = false;
        let myChart = this.$echarts.init(document.getElementById("main"));
        myChart.setOption({
          color:["#7e7e7e","#de5444"],
          title: {
            text: "参与购买数据统计",
          },
          tooltip: {},
          legend: {
            right: "0",
            orient: "vertical",
            data: ['参与', '购买']
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: res.label
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name:"参与",
              data: res.value,
              type: 'line',
              smooth: true,
              areaStyle: {}
            },
            {
              name:"购买",
              data: res.o_value,
              type: 'line',
              smooth: true,
              areaStyle: {}
            },
          ]
        });
      })
    }
  }
}
</script>

<style scoped>

#main {
  height: 350px;
  width: 100%;
}
</style>