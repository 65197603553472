<template>
  <el-row :gutter="20">
    <el-col type="flex" justify="center" align="middle" :span="4" v-for="(item,key) in desc" :key="key">
      <el-card shadow="hover" :body-style="{padding:'1rem 1rem',backgroundColor:item.bg_color}" style="color: #FFFFFF;width: 100%;position: relative">
        <div class="flex-def flex-zBetween" style="font-size: .8rem;width: 100%;text-align: left">
          <div style="line-height: 1.5rem">
            <div style="font-weight: 600;font-size: 1rem">{{item.num}}{{item.unit}}</div>
            <div>{{item.title}}</div>
          </div>
          <i style="color: rgba(0,0,0,.2);font-size: 3rem;position: absolute;right: .5rem;top: .5rem" :class="item.icon"></i>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "index_desc",
  data(){
    return{
      desc:{
        user:{
          title:"会员用户",
          num:0,
          unit:"人",
          icon:"el-icon-user-solid",
          bg_color:"#24a65a"
        },
        active:{
          title:"创建活动",
          num:0,
          unit: "场",
          icon: "el-icon-s-flag",
          bg_color: "#0ac0ef"
        },
        apply:{
          title:"累计参与",
          num:0,
          unit:"次",
          icon:"el-icon-s-marketing",
          bg_color:"#f3a027"
        },
        award_num:{
          title:"返利奖励",
          num:0,
          unit:"个",
          icon:"el-icon-s-ticket",
          bg_color:"#42cece"
        },
        paid:{
          title:"累计支付",
          num:0,
          unit:"元",
          icon:"el-icon-s-goods",
          bg_color:"#6864aa"
        },
        award_price:{
          title:"累计返利",
          num:0,
          unit:"元",
          icon:"el-icon-s-promotion",
          bg_color:"#de5444"
        }
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$api.active.homeDesc().then(res=>{
        this.desc.user.num = res.member;
        this.desc.active.num = res.active;
        this.desc.apply.num = res.active_join;
        this.desc.award_num.num = res.reward;
        this.desc.paid.num = res.order_fee;
        this.desc.award_price.num = res.award_fee;
      })
    }
  }
}
</script>

<style scoped>

</style>