<template>
  <div v-loading="true" id="main"></div>
</template>

<script>

export default {
  name: "coupon",
  components: {},
  data() {
    return {}
  },
  mounted() {
    this.loading = true;
    this.$api.active.homeCoupon().then(res=>{
      this.loading = false;
      let myChart = this.$echarts.init(document.getElementById("main"));
      myChart.setOption({
        color:["#7e7e7e","#de5444"],
        title: {
          text: "卡券领取核销数据统计",
        },
        tooltip: {},
        legend: {
          right: "0",
          orient: "vertical",
          data: ['领取', '核销']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.label
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name:"领取",
            data: res.value,
            type: 'line',
            smooth: true,
            areaStyle: {}
          },
          {
            name:"核销",
            data: res.o_value,
            type: 'line',
            smooth: true,
            areaStyle: {}
          },
        ]
      });
    })
  }
}
</script>

<style scoped>

#main {
  height: 350px;
  width: 100%;
}
</style>