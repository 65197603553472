<template>
  <el-card shadow="hover" :body-style="{padding:'1rem'}" style="width: 100%;position: relative">
    <template #header>
      <div class="y-pointer flex-def flex-zBetween flex-cCenter" style="position: relative;height:100%">
        <span><i class="el-icon-data-analysis"></i>待办事项</span>
        <div class="flex-def" style="height: 100%;position: absolute;right: -1rem">
          <div @click="tapTag(0)" :class="active_tag === 0?'active':''" class="tab-btn">新进会员</div>
          <div v-if="$store.state.user.info.founder === 1" @click="tapTag(1)" :class="active_tag === 1?'active':''" class="tab-btn">待审商家</div>
          <div @click="tapTag(2)" :class="active_tag === 2?'active':''" class="tab-btn">最新订单</div>
        </div>
      </div>
    </template>
    <el-scrollbar style="height: 18rem;width: 100%">
      <member v-if="active_tag === 0"></member>
      <merchant v-if="active_tag === 1"></merchant>
      <order v-if="active_tag === 2"></order>
    </el-scrollbar>
  </el-card>
</template>

<script>
import member from "@/view/home/index_wait/member";
import merchant from "@/view/home/index_wait/merchant";
import order from "@/view/home/index_wait/order";
export default {
  name: "index_wait",
  components: {
    member,
    merchant,
    order,
  },
  data() {
    return {
      active_tag:0,
    }
  },
  methods:{
    tapTag(index){
      this.active_tag = index
    }
  }
}
</script>

<style scoped>
.tab-btn{
  padding: 0 1rem;
  font-size: .8rem;
}
.active{
  border-bottom: 2px solid #62efff;
  background-color: #f8f8f8;
}
#main {
  height: 400px;
  width: 100%;
}
</style>