<template>
  <div v-loading="loading" class="flex-def" style="width: 100%;height: 100%;">
    <el-row :gutter="20" style="width: 100%">
      <el-col v-for="(item,key) in list" :key="key" :span="3">
        <el-avatar :size="50" :src="item.avatar | tomedia"></el-avatar>
        <div class="text-ellipsis" style="margin-top: -.3rem;font-size: .8rem">{{ item.nickname }}</div>
        <div class="flex-def flex-cCenter flex-zCenter" style="padding-bottom: 1rem">
          <div v-if="item.sex === 1"
               style="background-color: #dd5344;color: #FFFFFF;font-size: 9px;padding: 0 .5rem;border-radius: 1rem">女士
          </div>
          <div v-if="item.sex === 2"
               style="background-color: #0069ba;color: #FFFFFF;font-size: 9px;padding: 0 .5rem;border-radius: 1rem">男士
          </div>
          <div v-if="item.sex === 0"
               style="background-color: #d7d8dd;font-size: 9px;padding: 0 .5rem;border-radius: 1rem">未知
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "member",
  data() {
    return {
      list: [],
      loading: false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$api.member.search({
        must_avatar: true,
      }).then(res => {
        this.list = res.list;
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>